import React, { Component } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { Segment, Accordion, Icon, Button } from "semantic-ui-react";
import Helmet from "react-helmet";
import moment from "moment-mini";
import Layout from "../components/Layout";
import styled from "styled-components";
import { mapSizesToProps } from "../utils/mediaSizr";
import withSizes from "react-sizes";
import * as colors from "../utils/colors";

const StyledPublicationTitle = styled.div`
	font-weight: 400;
`;

const StyledPublicationMeta = styled.div`
	font-size: 0.85rem;
	color: ${colors.text.alpha(.7)};
`;

class PublicationsPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeIndex: -1,
		};
	}

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;
		this.setState({ activeIndex: newIndex });
	}

	renderPublications = () => {
		const { activeIndex } = this.state;
		return (
			<Segment.Group>
				{ this.props.data.allPublicationsJson.edges.map((publication, i) => (
					<Segment
						padded={this.props.isMobile ? false : true}
						key={publication.node.key}>

						<StyledPublicationTitle>
							<Button basic circular
								as="a"
								href={`https://bit.ly/${publication.node.key}`}
								rel="noopener"
								target="_blank"
								icon="linkify"
								size="mini"
							/>
							{` ${publication.node.title}`}
						</StyledPublicationTitle>
						<StyledPublicationMeta>
							{`${publication.node.publication}, ${publication.node.location}.
					${moment(publication.node.date, "YYYYMM").format("MMM, YYYY")}. doi:${publication.node.doi}`}
						</StyledPublicationMeta>
						<Accordion>
							<Accordion.Title active={activeIndex === i} index={i} onClick={this.handleClick}>
								<Icon name="dropdown"/>
						Abstract
							</Accordion.Title>
							<Accordion.Content active={activeIndex === i} style={{fontSize: "1rem"}}>
								{publication.node.abstract}
							</Accordion.Content>
						</Accordion>
					</Segment>
				)
				) }
			</Segment.Group>
		);
	}

	render() {
		const title = "Publications";
		return (
			<Layout title={title} main={true}>
				<Helmet title={title}/>
				{this.renderPublications()}
			</Layout>
		);
	}

}

PublicationsPage.propTypes = {
	data: PropTypes.object,
	isMobile: PropTypes.bool
};
export default withSizes(mapSizesToProps)(PublicationsPage);

export const pageQuery = graphql`
	query PublicationsPageQuery {
		allPublicationsJson (sort: {fields: [date], order: DESC}) {
			edges {
				node {
					key
					title
					publication
					location
					date
					doi
					abstract
				}
			}
		}
	}
`;
